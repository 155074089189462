import Vue from 'vue'
import './set-public-path'
import App from './App.vue'
import VueI18n from "vue-i18n";
import EN from "./localization/en";
import axios from 'axios'
import router from "./router";
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import Multiselect from 'vue-multiselect'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import commonMixin from '../src/assets/common/vue-common.js'
import ListingcommonMixin from '../src/assets/common/vue-commonList.js'
import ListingStatus from './components/Common/ListingStatus.vue'
import NumberInput from './components/Common/NumericField.vue'
import ModuleHeader from '../src/components/Header/ModuleHeader.vue';
import breadcrumb from './components/Common/BreadCrumb.vue'
import { createPinia, PiniaVuePlugin } from 'pinia';
import { useloggedUserInfo } from '../src/stores/UserInfo';
//import VueCompositionAPI from '@vue/composition-api'
import singleSpaVue from 'single-spa-vue';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import Loader from './components/Common/Loader.vue'
import Vue2ColorPicker from 'vue2-color-picker';
import moment from 'moment';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import vuescroll from 'vuescroll';
 var $ = require('jquery');
import draggable from "vuedraggable";
import Popover from 'vue-js-popover';
import VSwitch from 'v-switch-case';
import { DynamicForm } from 'tg-controls_cli/dist/myLib.common'
import {TgList} from 'tg-listv/dist/tglistv.common'
import './assets/treeselect.css'
// import './assets/base.css'
// const resourceFilePath = process.env.VUE_APP_LANGUAGE_FILE_URL;
// const envType = process.env.VUE_APP_NODE_ENV;
Vue.config.productionTip = false;
Vue.prototype.$= $;
require('./vee-validate');
 //Vue.use(VueCompositionAPI)
 Vue.use(VueI18n);
 Vue.use(VSwitch);
 Vue.use(PiniaVuePlugin);
 Vue.use(CKEditor);
 Vue.use(Popover,{ tooltip: true })
 Vue.component('draggable',draggable);
 Vue.component('v-date-picker', DatePicker)
 Vue.component('listing-status', ListingStatus)
 Vue.component('loader', Loader)
 Vue.component('number-input', NumberInput)
 Vue.component('breadcrumb', breadcrumb);
 Vue.component('ModuleHeader',ModuleHeader);
 Vue.component('Multiselect', Multiselect);
 Vue.component('dynamic-form',DynamicForm);
 Vue.component('tg-list',TgList);
 Vue.use(VueTelInput);
 Vue.use(Vue2ColorPicker);
 Vue.use(vuescroll);
 
 const pinia=createPinia();
 window.pinia = pinia;
 Vue.use(pinia);

//  let parsedLocalStorage = localStorage.getItem('TALGYEN_NAVBAR_SETTING');
//  let selectedLang = "en"; // Default to English
 
//  if(parsedLocalStorage) {
//    const settings = JSON.parse(parsedLocalStorage);
//    selectedLang = settings.lang.toLowerCase() || "en"; // Use stored language or default to English
//  }
//  const i18n = new VueI18n({
//    locale: selectedLang,
//    messages: {
//    },
//  });
const i18n = new VueI18n({
  locale: "en",
  messages: {
    en:EN,
  },
});
Vue.mixin(commonMixin, ListingcommonMixin);
Vue.filter('formatDate', function (value){
  if (value) {
    return moment(value, "YYYY-MM-DD").format(String('MM/DD/YYYY'))
}
})
Vue.filter('formatTime', function (value){
  if (value) {
    return moment(value, "HH:mm:ss").format(String('h:mm A'));
}
})
Vue.filter('formatDatee', function (value){
  if (value) {
    return moment(value, "YYYY-MM-DD").format(String('YYYY-MM-DD'));
}
})
Vue.filter('formatDateTime', function (value){
  if (value) {
    return moment(value, "YYYY-MM-DD HH:mm:ss").format(String('MM/DD/YYYY h:mm A'))
}
})
Vue.filter('currency', function (value){
  if (value) {
    var n = Number.parseFloat(value);
    if (!value || isNaN(n) || n < 0) return 0;
    return n.toFixed(2);
} else {
    return value;
}
})
Vue.filter('truncate', function (text, length, suffix) {
  if (text.length > length) {
      return text.substring(0, length) + suffix;
  } else {
      return text;
  }
});

// const loadedLanguages = []

// function setI18nLanguage (lang) {
//   i18n.locale = lang
//   // axios.defaults.headers.common['Accept-Language'] = lang
//   // document.querySelector('html').setAttribute('lang', lang)
//   // return lang
// }

// async function loadLanguageAsync (lang) {
//   if (loadedLanguages.includes(lang)) {
//     if (i18n.locale !== lang) setI18nLanguage(lang)
//     return Promise.resolve()
//   }

//   axios.get(`${resourceFilePath}/${envType}/${lang}.js`).then(response => {
//     let msgs = response.data
//     loadedLanguages.push(lang)
//     i18n.setLocaleMessage(lang, response.data[0])
//   })
// }

// // router.js
// router.beforeEach((to, from, next) => {
//   loadLanguageAsync(selectedLang).then(() => next());
// })

const containerSelector = '#page-2-container'
const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    pinia,
    i18n,
    router,
    render(h) {     
      return h(App, {
         props: {          
          props: {          
            userProfile:useloggedUserInfo().setUser(this.userProfile),
            classicEditor:useloggedUserInfo().setClassicEditor(this.classicEditor)
           } 
        } 
      });//
    },
    el: containerSelector
    
  }
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
// new Vue({
//   render: h => h(App),
//   pinia
// }).$mount('#app')
